import ApiService from "@/core/services/api.service";

// action types
export const ORDERS = "business/sales/orders";
export const INVOICE = "business/sales/orders/invoice";
export const CHANGE_STATUS = "business/sales/change-status";
export const GENERATE_LINK= "business/sales/send-payment-link";
export const UPDATE_LOADING ='updateLoading';

// mutation types
export const SET_ORDERS = "setOrders";
export const SET_ERROR = "setError";
export const SET_LOADING="setLoading";
const state = {
  orders: [],
  errors: null,
  loading:true,
};

const getters = {
  getOrders(state) {
    return state.orders;
  },
  getLoading(state){
    return state.loading
  }
};

const actions = {
  [INVOICE](context, form) {
    return new Promise(resolve => {
      ApiService.get(INVOICE , form)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
          });
    });
  },
  [ORDERS](context, form) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);

      ApiService.post(ORDERS , form)
        .then(({ data }) => {
          
          context.commit(SET_ORDERS, data.data);
          context.commit(SET_LOADING, false);

          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [CHANGE_STATUS](context, form) {
    return new Promise(resolve => {
      ApiService.post(CHANGE_STATUS , form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GENERATE_LINK](context, form) {
    return new Promise(resolve => {
      ApiService.post(GENERATE_LINK , form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [UPDATE_LOADING](context,bool){
    context.commit(SET_LOADING,bool);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ORDERS](state, obj) {
    state.orders = obj;
  },
  [SET_LOADING](state,bool){
    state.loading=bool

  }
};

export default {
  state,
  actions,
  mutations,
  getters
}
