import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import SanctumService from "@/core/services/sanctum.service";

axios.interceptors.request.use(
  function(config) {
    if (config.url !== 'business/sales/orders')
      document.body.classList.add('page-loading');
  
    const token = sessionStorage.getItem('ID_TOKEN');
    const isStaff = sessionStorage.getItem('is_staff');
    const staffId = sessionStorage.getItem('staff_id');
  
    // Set Authorization token
    config.headers.common.Authorization = `Bearer ${token}`;
  
    // If the user is staff, send additional headers
    if (isStaff == '1') {
      config.headers.common['X-Is-Staff'] = '1';
      config.headers.common['X-Staff-Id'] = staffId;
    }
  
    return config;
  },
  
  function(error) {
    document.body.classList.remove('page-loading');
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function(response) {
    document.body.classList.remove('page-loading');
      return Promise.resolve(response);
  },
  function(error) {
    if (error.toString().includes('401') && (! window.location.href.toString().includes('login')))
    {
        window.location = '/#/login';
    }
    if (error.toString().includes('419') && (!window.location.href.toString().includes('login'))) {
      window.location = '/#/login';
    }
    document.body.classList.remove('page-loading');
      return Promise.reject(error);
  }
);
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {

  init() {
    Vue.use(VueAxios, axios);
    console.log('process.env.VUE_APP_API_URL',process.env.VUE_APP_API_URL)
    
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    Vue.axios.defaults.headers.common["Accept"] = "application/json";
    Vue.axios.defaults.headers.common["Content-Type"] = "application/json";
    Vue.axios.defaults.withCredentials = true;
  },

  /**
   * Set the default HTTP request headers
   */
  // setHeader() {
  //   Vue.axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer `+sessionStorage.getItem('ID_TOKEN');
  // },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  setCookie() {
    return Vue.axios
      .get(process.env.VUE_APP_API_BASE_URL + "sanctum/csrf-cookie")
      .catch(error => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    let url = resource;
    if (slug) url += "/" + slug;

    return Vue.axios.get(url).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    console.log('resource',resource)
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  fileUpload(resource, params) {
    return Vue.axios.post(
      `${resource}`,
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'boundary': Math.random().toString().substr(2)
        }
      }
    );
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
