const Permissions = {
    install(Vue) {
      // Permission check method
      Vue.prototype.$hasPermission = function(requiredPermission) {
        // Non-staff users (admins) have all permissions
        if (sessionStorage.getItem('is_staff') !== '1') {
          return true;
        }
  
        try {
          const permissions = JSON.parse(sessionStorage.getItem('staff_permissions')) || [];
          return permissions.some(perm => perm.name === requiredPermission);
        } catch (e) {
          console.error('Permission check error:', e);
          return false;
        }
      };
  
      // Check multiple permissions (all required)
      Vue.prototype.$hasAllPermissions = function(...requiredPermissions) {
        return requiredPermissions.every(perm => this.$hasPermission(perm));
      };
  
      // Check multiple permissions (any required)
      Vue.prototype.$hasAnyPermission = function(...requiredPermissions) {
        return requiredPermissions.some(perm => this.$hasPermission(perm));
      };
    }
  };
  
  export default Permissions;