import Vue from "vue";
import Router from "vue-router";

// Updated router.beforeEach guard
Vue.use(Router);


const router = new Router({
  routes: [
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-new"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-new"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-new"),
        },
      ],
    },

    {
      path: "/admin",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "admindashboard",
          component: () => import("@/view/pages/admin/Dashboard.vue"),
        },
        {
          path: "businesses",
          name: "businesses",
          component: () => import("@/view/pages/admin/Businesses.vue"),
        },
        {
          path: "billing",
          name: "billing",
         component: () => import("@/view/pages/admin/Billing.vue"),
        }, 
       
        {
          path: "businesses/edit",
          name: "businesses.edit",
          component: () => import("@/view/pages/admin/Businesses-Edit.vue"),
        },
        {
          path: "reservation",
          component: () => import("@/view/pages/admin/Reservation.vue"),
        },
        {
          path: "categories",
          component: () => import("@/view/pages/admin/Categories.vue"),
        },
        {
          path: "dish-properties",
          component: () => import("@/view/pages/admin/DishPropeties.vue"),
        },
        {
          path: "dish-properties-group",
          component: () => import("@/view/pages/admin/PropertiesGroup.vue"),
        },
        {
          path: "toppings",
          component: () => import("@/view/pages/admin/Toppings.vue"),
        },
        {
          path: "topping-groups",
          component: () => import("@/view/pages/admin/ToppingGroups.vue"),
        },
        {
          path: "items",
          component: () => import("@/view/pages/admin/Items.vue"),
        },
        {
          path: "opening-times",
          component: () => import("@/view/pages/admin/OpeningTimes.vue"),
        },
        {
          path: "delivery-collection-timings",
          component: () => import("@/view/pages/admin/DeliveryCollectionTimings.vue"),
        },
        {
          path: "orders",
          component: () => import("@/view/pages/admin/Orders.vue"),
        },
        {
          path: "order-items",
          component: () => import("@/view/pages/admin/OrderItems.vue"),
        },
        {
          path: "vouchers",
          component: () => import("@/view/pages/admin/VoucherCodes.vue"),
        },
        {
          path: "user-vouchers",
          component: () => import("@/view/pages/admin/UserVoucherCodes.vue"),
        },
      ],
    },
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        { 
          path: "/orders",
          name: "orders",
          component: () => import("@/view/pages/error/Error.vue"),
          children: [
            {
              path: "today",
              name: "today",
              component: () => import("@/view/pages/orders/Today.vue"),
              meta: { permission: 'view orders' }

            },
            {
              path: "pending",
              name: "pending",
              component: () => import("@/view/pages/orders/Pending.vue"),
              meta: { permission: 'view orders' }

            },
            {
              path: "detail",
              name: "detail",
              props: true,
              component: () => import("@/view/pages/orders/OrderDetail.vue"),
              meta: { permission: 'view orders' }

            },
            {
              path: "history",
              name: "history",
              component: () => import("@/view/pages/orders/OrderHistory.vue"),
              meta: { permission: 'view orders' }

            },
            {
              path: "cancelled",
              name: "cancelled",
              component: () => import("@/view/pages/orders/OrderHistoryCancelled.vue"),
              meta: { permission: 'view orders' }

            },
            {
              path: "combined",
              name: "combined",
              component: () => import("@/view/pages/orders/OrderHistoryCombined.vue"),
              meta: { permission: 'view orders' }

            },
          ],
          meta: { permission: 'view orders' }

        },
        {
          path: "/menu-manager",
          name: "menu-manager",
          component: () => import("@/view/pages/error/Error.vue"),
          children: [
            {
              path: "categories",
              name: "categories",
              component: () => import("@/view/pages/menu/Category.vue"),
              meta: { permission: 'view menues' }

            },
            {
              path: "products",
              name: "products",
              component: () => import("@/view/pages/menu/Product.vue"),
              meta: { permission: 'view menues' }

            },
            {
              path: "toppings",
              name: "toppings",
              component: () => import("@/view/pages/menu/Topping.vue"),
              meta: { permission: 'view menues' }

            },
            {
              path: "properties",
              name: "properties",
              component: () => import("@/view/pages/menu/DishProperty.vue"),
              meta: { permission: 'view menues' }

            },
          ],
        },
        {
          path: "/system-setting",
          name: "system-setting",
          component: () => import("@/view/pages/error/Error.vue"),
          children: [
            {
              path: "contact-details",
              name: "wizard-contact-details",
              component: () => import("@/view/pages/business/components/ContactInfo.vue"),
              meta: { permission: 'view system settings' }

            },
            {
              path: "restaurant-details",
              name: "wizard-restaurant-details",
              component: () => import("@/view/pages/business/components/RestaurantType.vue"),
                 meta: { permission: 'view system settings' }

            },
            {
              path: "menu-details",
              name: "wizard-menu-details",
              component: () => import("@/view/pages/business/components/Menu.vue"),
              meta: { permission: 'view system settings' }

            },
            {
              path: "social-link-details",
              name: "wizard-social-link-details",
              component: () => import("@/view/pages/business/components/Social.vue"),
              meta: { permission: 'view system settings' }

            },
            {
              path: "branding-details",
              name: "wizard-branding-details",
              component: () => import("@/view/pages/business/components/Branding.vue"),
              meta: { permission: 'view system settings' }

            },
            {
              path: "opening-timings-details",
              name: "wizard-opening-timings-details",
              component: () => import("@/view/pages/business/components/OpeningTimes.vue"),
              meta: { permission: 'view system settings' }

            },
            {
              path: "delivery-charges-details",
              name: "wizard-delivery-charges-details",
              component: () => import("@/view/pages/business/components/DeliveryCharge.vue"),
              meta: { permission: 'view system settings' }

            },
            {
              path: "delivery-collection-details",
              name: "wizard-collection-details",
              component: () => import("@/view/pages/business/components/DeliveryCollectionTimeInfo.vue"),
              meta: { permission: 'view system settings' }

            },
            {
              path: "domain",
              name: "domain",
              component: () => import("@/view/pages/business/Domain.vue"),
              meta: { permission: 'view system settings' }

            },
            {
              path: "billing",
              name: "billing",
              component: () => import("@/view/pages/business/Billing.vue"),
              meta: { permission: 'view system settings' }

            },
            {
              path: "qrcode",
              name: "qrcode",
              component: () => import("@/view/pages/business/QrCode.vue"),
              meta: { permission: 'view system settings' }

            },
            {
              path: "account-delete",
              name: "account-delete",
              component: () => import("@/view/pages/business/AccountDelete.vue"),
            },
          ],
        },
        {
          path: "/business",
          name: "business",
          component: () => import("@/view/pages/error/Error.vue"),
          children: [
            {
              path: "staff",
              name: "staff",
             component: () => import("@/view/pages/business/components/Staff.vue"),
             meta: { permission: 'view staff' }

            }, 
            {
              path: "announcements",
              name: "announcements",
              component: () => import("@/view/pages/business/Announcements.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "offers",
              name: "offers",
              component: () => import("@/view/pages/business/Offers.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "announcement",
              name: "announcement",
              component: () => import("@/view/pages/business/Announcement.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "info",
              name: "info",
              component: () => import("@/view/pages/business/Info.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "contact-us",
              name: "messages",
              component: () => import("@/view/pages/business/Messages.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "customers",
              name: "users",
              component: () => import("@/view/pages/business/Users.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "templateinfo",
              name: "templateinfo",
              component: () => import("@/view/pages/business/TemplateInfo.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "reservations",
              name: "reservations",
              component: () => import("@/view/pages/business/Reservations.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "reservation-settings",
              name: "reservation-settings",
              component: () => import("@/view/pages/business/components/settings/ReservationSettings.vue"),
              meta: { permission: 'view business settings' }

            },
            
            {
              path: "delivery-collection",
              name: "delivery-collection",
              component: () => import("@/view/pages/business/DeliveryInfo.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "opening-times",
              name: "opening-times",
              component: () => import("@/view/pages/business/OpeningTime.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "wizard",
              name: "wizard",
              component: () => import("@/view/pages/business/Wizard.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "settings",
              name: "settings",
              component: () => import("@/view/pages/business/Setting.vue"),
              meta: { permission: 'view business settings' }

            },
            {
              path: "pos-import",
              name: "pos-import",
              component: () => import("@/view/pages/business/PosImport.vue"),
              meta: { permission: 'view business settings' }

            },
          ],
        },
      ],
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/business-claim/:token",
      component: () => import("@/view/pages/auth/login_pages/Claim-business")
    },
    {
      path: "/forgot-password",
      component: () => import("@/view/pages/auth/Forgot-password")
    },
    {
      path: "/reset-password/:token",
      component: () => import("@/view/pages/auth/Forgot-password-reset")
    },

    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
function hasPermission(userPermissions, requiredPermission) {
  if (!requiredPermission) return true; // No permission required
  if (!userPermissions) return false; // No permissions provided
  return userPermissions.some(perm => perm.name === requiredPermission);
}
function checkAuth() {
  // Check for valid auth token and it's not expired
  const token = sessionStorage.getItem('ID_TOKEN');
  
  if (!token) return false;

  return true;
}

// Router guard implementation
router.beforeEach((to, from, next) => {
  // Skip permission checks for admin routes
  if (to.matched.some(record => record.meta.isAdminRoute)) {
    return next();
  }

  
  // Check if route requires auth

  if (to.name === 'login' || to.name=='404') {
    return next();
  }
    // Check permissions for staff users
    
    const isStaff = sessionStorage.getItem('is_staff') === '1';
    if (isStaff) {
      try {
        const userPermissions = JSON.parse(sessionStorage.getItem('staff_permissions') || '[]');
        const routePermission = to.meta.permission;

        if ( !hasPermission(userPermissions, routePermission)) {
          return next('/404');
        }
      } catch (e) {
        console.error('Permission check error:', e);
        return next('/error-1');
      }
    }
  

  next();
});

export default router;


